
import { Vue, Component } from 'vue-property-decorator';
import { userService } from '../services';

@Component
export default class Logout extends Vue {
  async beforeCreate() {
    try {
      document.title = 'AgileMate Logout';
      this.$analytics.logEvent('logout', { user_id: userService.getUserId(), username: userService.getName() });
      await userService.logout();
      //await this.$store.dispatch('user/logout');
      this.$bus.$emit('snackbar-notify', 'Successfully logged out', 'success');
      const nextRoute = (this.$route.query['redirectFrom'] as string) || 'home';
      this.$router.push(nextRoute).catch(() => null); // Prevent router navigation guard error
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  render(h) {
    return;
  }
}
